export function getLocalStorageItem(key, defaultValue = null) {
  try {
    if (typeof localStorage === 'undefined') return defaultValue

    return localStorage.getItem(key)
  } catch (err) {
    return defaultValue
  }
}

export function setLocalStorageItem(key, value) {
  try {
    if (typeof localStorage === 'undefined') return
    localStorage.setItem(key, value)
    // eslint-disable-next-line no-empty
  } catch (err) {}
}
