import { getCookies } from './cookies'

// Note: ideally we should retrieve this string from src/contexts/Consent.
const COOKIE_KEY_ANALYTICS = '_scw_rgpd_analytics'

/**
 * Determines if the user has consentend for analytics tracking.
 *
 * @returns True if the user has consented
 */
export const hasConsentedAnalytics = () => {
  const cookies = getCookies()
  const [, cookieValue] =
    cookies.find(([key]) => key === COOKIE_KEY_ANALYTICS) || []

  return cookieValue === 'true'
}

export const loadAnalytics = key => {
  if (window.analytics && !window.analytics.initialized) {
    window.analytics.load(key)
  }
}
