import {
  ColorModeProvider,
  Preflight,
  ThemeProvider,
} from '@xstyled/styled-components'
import theme from '@documentations/commons/styles/theme'
import GlobalStyle from '@documentations/commons/styles/GlobalStyle'

import { SwThemeProvider } from '../contexts/SwTheme'
import { ThemeModeProvider } from '../contexts/ThemeMode'
import { SearchProvider } from '../contexts/Search'

import { ConsentsProvider } from '../contexts/Consents'
import { ThirdPartyProviders } from '../contexts/ThirdParty'

export const CommonsProvider = ({ children }) => (
  <>
    <Preflight />
    <ThemeModeProvider>
      <SwThemeProvider>
        <ThemeProvider theme={theme}>
          <ConsentsProvider>
            <ThirdPartyProviders>
              <ColorModeProvider>
                <SearchProvider>
                  <GlobalStyle />
                  {children}
                </SearchProvider>
              </ColorModeProvider>
            </ThirdPartyProviders>
          </ConsentsProvider>
        </ThemeProvider>
      </SwThemeProvider>
    </ThemeModeProvider>
  </>
)
