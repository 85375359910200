import { Link as GatsbyLink } from 'gatsby'
import bytes from 'bytes'
import styled, { x } from '@xstyled/styled-components'
import PdfIcon from '../assets/icons/pdf.inline.svg'
import { Svg } from './Svg'
import externalIcon from '../assets/icons/external.inline.svg'

import { useAssets } from '../contexts/Assets'

export const A = styled(x.a)`
  color: secondary;
  font-weight: semibold;
  text-decoration: none;
  :hover,
  :focus {
    text-decoration: underline;
  }
`

function Pdf({ href, children, className, ...props }) {
  const pdf = useAssets(href)

  return (
    <External
      as={A}
      href={pdf?.publicURL}
      display="inline-flex"
      flexDirection={{ _: 'column', sm: 'row' }}
      alignItems={{ _: 'flex-start', sm: 'baseline' }}
      ml={0.5}
      className={`${className || ''} a-flex-centered`}
      {...props}
    >
      <Svg color="primary" ml={0.5} mr={1} asset={PdfIcon} size={5} />
      {children} (PDF, {bytes.format(pdf?.size)})
    </External>
  )
}

function External({ href, children, icon, hasExternalIcon = true, ...props }) {
  return (
    <x.a
      href={href}
      target="_blank"
      display="inline-block"
      rel="noopener"
      {...props}
    >
      {children}
      {hasExternalIcon &&
        (icon || (
          <Svg
            display="inline"
            opacity={0.6}
            color="transparent"
            stroke="currentColor"
            viewBox="0 0 14 14"
            ml={0.5}
            asset={externalIcon}
            size={3}
            verticalAlign="baseline"
          />
        ))}
    </x.a>
  )
}

export function Link({
  to = '',
  hasExternalIcon,
  href = '',
  clickAnchor = () => {},
  ...props
}) {
  if (!to && !href) return <x.a aria-disabled="true" {...props} />

  const isExternal = /^http/.test(href) || /^http/.test(to)
  const isMail = /^mailto:/.test(href) || /^mailto:/.test(to)
  const isFtp = /^ftp:/.test(href) || /^ftp:/.test(to)
  const isHash = /^#/.test(href) || /^#/.test(to)

  if (isHash) {
    return <A href={href || to} onClick={clickAnchor} {...props} />
  }

  if (href.includes('.pdf') && !isExternal) {
    return <Pdf href={href} {...props} />
  }

  if (isExternal || isMail || isFtp) {
    return (
      <External
        as={href && A}
        href={href || to}
        hasExternalIcon={hasExternalIcon}
        {...props}
      />
    )
  }

  if (href) {
    const to = href.replace(
      new RegExp(`^${process.env.GATSBY_PATH_PREFIX}`),
      '/',
    )

    return <A as={GatsbyLink} to={to} {...props} />
  }

  return <x.a as={GatsbyLink} to={to} {...props} />
}

export const a = Link
