import {
  Children,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react'
import { createPortal } from 'react-dom'
import { x } from '@xstyled/styled-components'

import FocusTrap from 'focus-trap-react'

import { useDialog } from '@documentations/commons/contexts/Dialog'
import cross from '../../assets/cross.svg'

const portalRoot = globalThis?.document?.getElementById('portal') || null

function reducer(state, newState) {
  const reduced = {
    ...state,
    isOpen: newState.isOpen,
    ...(!state.noOverflowToggle
      ? {
          toggleBodyOverflow: newState.isOpen
            ? (globalThis.document.body.style.overflow = 'hidden')
            : (globalThis.document.body.style.overflow = 'initial'),
        }
      : {}),
  }

  return reduced
}

function enhanceChildren(children) {
  return Children.map(children, child => ({
    ...child,
    props: {
      ...(child?.props || {}),
    },
  })).reduce((acc, component) => {
    const name = component?.type?.displayName
    if (!acc[name]) {
      acc[name] = []
    }
    acc[name].push(component)

    return acc
  }, {})
}

export default function Dialog({ noOverflowToggle = false, children }) {
  const { visible } = useDialog()

  const [state, dispatch] = useReducer(reducer, {
    isOpen: false,
    noOverflowToggle,
  })
  const { isOpen } = state

  const open = useCallback(() => {
    dispatch({
      isOpen: true,
    })
  }, [])

  const close = useCallback(() => {
    dispatch({
      isOpen: false,
    })
  }, [])

  const { trigger, body } = useMemo(
    () => enhanceChildren(children, close),
    [children, close],
  )

  useEffect(() => {
    if (!visible) close()
  }, [close, visible])

  return (
    <>
      <Trigger open={open}>{trigger}</Trigger>
      {visible && isOpen && (
        <Window close={close} dispatch={dispatch} isOpen={isOpen}>
          {body}
        </Window>
      )}
    </>
  )
}

function Trigger({ open = () => {}, children }) {
  return (
    <x.button
      aria-label="Open"
      onClick={open}
      display="flex"
      bg="transparent"
      p={0}
      m={0}
    >
      {children || 'Dialog trigger'}
    </x.button>
  )
}

Dialog.Trigger = ({ children }) => children
Dialog.Trigger.displayName = 'trigger'

Dialog.Body = ({ children }) => <x.div px={{ md: 4, _: 2 }}>{children}</x.div>
Dialog.Body.displayName = 'body'

function Window({
  isOpen = false,
  dispatch = () => {},
  close = () => {},
  children,
}) {
  const ref = useRef()

  const clickOutside = useCallback(
    e => {
      if (ref && ref.current) {
        if (!ref.current.contains(e.target) && isOpen) {
          dispatch({
            isOpen: false,
          })
        }
      }
    },
    [isOpen, dispatch],
  )

  const keyDown = useCallback(
    ({ keyCode = null }) => {
      if (keyCode === 27) {
        dispatch({
          isOpen: false,
        })
      }
    },
    [dispatch],
  )

  return createPortal(
    <FocusTrap>
      <x.div
        onClick={clickOutside}
        position="fixed"
        zIndex={10001}
        top={0}
        left={0}
        bottom={0}
        right={0}
        display={globalThis.document ? 'block' : 'none'}
        overflow="auto"
        bg="primary-a56"
      >
        <x.div display="flex" alignItems="center" h={1} py={3} mx={2}>
          <x.aside
            ref={ref}
            role="dialog"
            aria-labelledby="dialog-native-title"
            aria-modal="true"
            onKeyDown={keyDown}
            overflow="hidden"
            w={{ sm: 'fit-content', _: 'auto' }}
            h={{ sm: 'fit-content', _: 'auto' }}
            borderRadius="3xl"
            boxShadow="blur-purple"
            bg="white"
            m="auto"
          >
            <x.div display="flex" w={1}>
              <x.button
                aria-label="Close"
                title="Close"
                onClick={close}
                display="flex"
                justifyContent="center"
                alignItems="center"
                w={14}
                h={14}
                border="none"
                borderRadius="0 3xl 0 0"
                opacity={{ hover: 0.5 }}
                bg="transparent"
                color="stone"
                ml="auto"
                autoFocus
              >
                <x.img src={cross} alt="Close" w="24px" h="24px" />
              </x.button>
            </x.div>
            <x.div overflow="hidden" pb={5}>
              {children}
            </x.div>
          </x.aside>
        </x.div>
      </x.div>
    </FocusTrap>,
    portalRoot,
  )
}
