const COOKIE_ORGANIZATION_ID = '_console_uid'

export function getCookies() {
  if (typeof window === 'undefined') return []

  return (
    (window.document.cookie || '')
      .split(';')
      .map(item => item.trim().split('=')) || []
  )
}

export function getOrganizationIdFromCookie() {
  const cookies = getCookies()

  const [, organizationId = ''] =
    cookies.find(([key]) => key === COOKIE_ORGANIZATION_ID) || []

  return organizationId
}
