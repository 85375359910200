import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { getLocalStorageItem } from '../helpers/localStorage'

const ThemeModeContext = createContext({
  themeMode: 'default',
  toggleThemeMode: () => {},
})

export function ThemeModeProvider({ children }) {
  const [themeMode, setThemeMode] = useState(
    getLocalStorageItem('xstyled-color-mode') || 'default',
  )

  const toggleThemeMode = useCallback(() => {
    setThemeMode(currentThemeMode =>
      currentThemeMode === '_dark' ? 'default' : '_dark',
    )
  }, [])

  const value = useMemo(
    () => ({
      themeMode,
      toggleThemeMode,
    }),
    [themeMode, toggleThemeMode],
  )

  return (
    <ThemeModeContext.Provider value={value}>
      {children}
    </ThemeModeContext.Provider>
  )
}

export function useThemeMode() {
  return useContext(ThemeModeContext)
}
