import { GatsbyBrowser } from 'gatsby'
import CookieBanner from '@documentations/commons/components/Cookie'
import { DialogProvider } from '@documentations/commons/contexts/Dialog'
import { CommonsProvider } from '@documentations/commons/components/CommonsProvider'
import { hasConsentedAnalytics } from '@documentations/commons/helpers/analytics'
import { getOrganizationIdFromCookie } from '@documentations/commons/helpers/cookies'

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({
  location: { pathname },
}) => {
  if (hasConsentedAnalytics() && window.analytics) {
    setTimeout(() => {
      window.analytics.page({
        page_type: 'Developers',
        path: pathname,
        userId: getOrganizationIdFromCookie(),
      })
    }, 0)
  }
}

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
}) => (
  <CommonsProvider>
    <DialogProvider>
      <CookieBanner />
      {element}
    </DialogProvider>
  </CommonsProvider>
)
