import { createContext, useContext } from 'react'

const defaultValues = []

const AssetsContext = createContext(defaultValues)
export function AssetsProvider({ assets = [], children }) {
  return (
    <AssetsContext.Provider value={assets}>{children}</AssetsContext.Provider>
  )
}

export function useAssets(src) {
  const assets = useContext(AssetsContext)

  if (!src) return null

  return assets.find(asset => asset.base === src)
}

export function extractLightboxesFromBody(body) {
  return [
    ...(body.matchAll(/(<Lightbox src=")([\s\S]*?)(")/g) || []),
    ...(body.matchAll(/(<Lightbox src={\[)([\s\S]*?)(]})/g) || []),
  ].reduce(
    (acc, [, , id]) => [
      ...acc,
      ...(id.includes(',') ? id.replace(/"/g, '').split(',') : [id]),
    ],
    [],
  )
}

export function extractPdfsFromBody(body) {
  return [...(body.matchAll(/(]\()([\s\S]*?)(\))/g) || [])]
    .reduce((acc, [, , id]) => [...acc, id], [])
    .filter(value => value.includes('.pdf'))
    .filter(value => !value.includes('http'))
}
