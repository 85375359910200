import React, { useContext, useMemo, useState } from 'react'

const defaultValue = {
  visible: true,
}
const Dialog = React.createContext(defaultValue)

export const DialogProvider = ({ children }) => {
  const [visible, show] = useState(true)

  const value = useMemo(
    () => ({
      visible,
      show,
    }),
    [visible, show],
  )

  return <Dialog.Provider value={value}>{children}</Dialog.Provider>
}

export const useDialog = () => useContext(Dialog)
export default Dialog
