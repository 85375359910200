import { useEffect, useState } from 'react'
import { Global, ThemeProvider } from '@emotion/react'
import {
  consoleDarkerTheme as darkTheme,
  consoleLightTheme as lightTheme,
} from '@ultraviolet/themes'
import inter400 from '@fontsource/inter/latin-400.css'
import inter500 from '@fontsource/inter/latin-500.css'
import inter600 from '@fontsource/inter/latin-600.css'
import { isEmbeddedDarkMode } from '../helpers/embedded'

import { useThemeMode } from './ThemeMode'

const themes = {
  default: lightTheme,
  _dark: darkTheme,
}

export function SwThemeProvider({ children }) {
  const { themeMode } = useThemeMode()
  const [theme, setTheme] = useState(lightTheme)

  useEffect(() => {
    setTheme(themes[isEmbeddedDarkMode() ? '_dark' : themeMode])
  }, [themeMode])

  return (
    <>
      <Global styles={[inter400, inter500, inter600]} />
      <ThemeProvider theme={{ ...theme, setTheme }}>{children}</ThemeProvider>
    </>
  )
}
