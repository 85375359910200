import { useCallback, useEffect, useId, useMemo, useState } from 'react'
import { css, styled, x } from '@xstyled/styled-components'
import { useConsents } from '@documentations/commons/contexts/Consents'
import { useDialog } from '@documentations/commons/contexts/Dialog'
import { p as P } from '../Typography'
import { Link } from '../InternalLink'
import Touchable from './Touchable'

const touchableStyle = {
  w: { _: 1, sm: 'auto', lg: 192 },
  px: { sm: 3, lg: 0 },
}

export default function Preference({ onConsent, ...props }) {
  const { acceptAll, consents, refuseAll, toggleChoices, validateChoices } =
    useConsents()
  const { show = () => {} } = useDialog()

  const commonItems = useMemo(
    () => [
      {
        borderTop: 0,
        hasToggle: false,
        subtitle: 'Always active',
        title: 'Essential',
      },
      {
        title: 'Analytics',
      },
      {
        title: 'Marketing',
      },
      {
        title: 'Advertising',
      },
    ],
    [],
  )

  const items = useMemo(
    () => [
      {
        description:
          'These cookies are required for the website to function properly and to allow you to use its services and features. See a list of the essential cookies we use',
        hreflang: 'en',
        ...commonItems[0],
      },
      {
        description:
          'These cookies are used to monitor the performance of our site and to enhance your browsing experience. See a list of the marketing & analytics cookies we use',
        hreflang: 'en',
        ...commonItems[1],
      },
      {
        description:
          'These cookies are used to understand user behavior in order to provide you with a more relevant browsing experience or personalize the content on our site. See a list of the marketing & analytics cookies we use',
        hreflang: 'en',
        ...commonItems[2],
      },
      {
        description:
          'These cookies are used to personalize and measure the effectiveness of advertising on our site and other websites. See a list of the advertising cookies we use',
        hreflang: 'en',
        ...commonItems[3],
      },
    ],
    [commonItems],
  )

  const refuse = useCallback(
    e => {
      e.preventDefault()
      refuseAll()
      show(false)
      onConsent?.()
    },
    [onConsent, refuseAll, show],
  )

  const accept = useCallback(
    e => {
      e.preventDefault()
      acceptAll()
      show(false)
      onConsent?.()
    },
    [acceptAll, onConsent, show],
  )
  const validate = useCallback(
    e => {
      e.preventDefault()
      validateChoices()
      show(false)
      onConsent?.()
    },
    [onConsent, show, validateChoices],
  )

  return (
    <x.form {...props}>
      <x.div
        color="stone"
        fontFamily="spacegrotesk"
        fontSize="2xl"
        fontWeight="semibold"
        lineHeight="snug"
        mb={1}
      >
        Select your preferred cookies 🍪
      </x.div>
      <P color="stone" py={0}>
        Help us improve our website! You can also consult our{' '}
        <Link
          to="https://www.scaleway.com/en/cookie/"
          hrefLang="en"
          textDecoration={{ _: 'none', hover: 'underline' }}
          fontWeight="semibold"
        >
          Cookie policy
        </Link>
        .
      </P>

      <x.fieldset p={0}>
        {items.map(({ hasToggle = true, title, subtitle, ...props }, index) => (
          <x.div key={index} borderTop borderColor="tertiary-500" pt={2} mt={2}>
            {hasToggle && (
              <Toggle
                change={toggleChoices}
                isChecked={consents[title.toLowerCase()]}
              >
                {title}
              </Toggle>
            )}
            {!hasToggle && (
              <x.div display="flex">
                <x.span
                  color="stone"
                  text="default"
                  fontFamily="spacegrotesk"
                  fontWeight="semibold"
                  mr={2}
                >
                  {title}
                </x.span>
                <x.span color="secondary" text="default" fontStyle="italic">
                  {subtitle}
                </x.span>
              </x.div>
            )}
            <ModalItem {...props} />
          </x.div>
        ))}
      </x.fieldset>

      <x.div
        display="flex"
        flexDirection={{ _: 'column-reverse', sm: 'row' }}
        justifyContent="center"
        flexWrap="wrap"
        mt={4}
      >
        <Touchable
          aria-controls="cookie-popup-banner"
          onClick={refuse}
          bg="tertiary-500"
          color="primary"
          {...touchableStyle}
          mr={{ sm: 2 }}
          mb={2}
        >
          Refuse all
        </Touchable>
        <Touchable
          aria-controls="cookie-popup-banner"
          onClick={accept}
          bg="tertiary-500"
          color="primary"
          {...touchableStyle}
          mr={{ sm: 2 }}
          mb={2}
        >
          Accept all
        </Touchable>
        <Touchable
          aria-controls="cookie-popup-banner"
          onClick={validate}
          bg="secondary"
          color="white"
          {...touchableStyle}
          mb={2}
        >
          Validate my choice
        </Touchable>
      </x.div>
    </x.form>
  )
}

function ModalItem({ description, hreflang }) {
  return (
    <P color="stone" py={0} mt={1}>
      {description}{' '}
      <Link
        to="https://www.scaleway.com/en/cookie/"
        hrefLang={hreflang}
        textDecoration={{ _: 'none', hover: 'underline' }}
        fontWeight="semibold"
      >
        here
      </Link>
      .
    </P>
  )
}

function Toggle({ isChecked, children, change = () => {} }) {
  const [checked, setChecked] = useState(false)
  const id = useId()
  const htmlForId = useMemo(() => `toggle-id-${id}`, [id])

  useEffect(() => {
    setChecked(isChecked || false)
  }, [isChecked])

  const toggle = useCallback(
    event => {
      setChecked(!checked)
      change(event, children)
    },
    [change, checked, children],
  )

  return (
    <ContainerStyled
      $checked={checked}
      position="relative"
      zIndex={0}
      display="flex"
      alignItems="center"
      w="fit-content"
    >
      <x.input
        id={htmlForId}
        type="checkbox"
        onChange={toggle}
        position="absolute"
        zIndex={10}
        top={0}
        left={0}
        bottom={0}
        right={0}
        w={1}
        h={1}
        opacity={0}
        p={0}
        m={0}
        checked={checked}
        cursor="pointer"
      />
      <x.div
        display="flex"
        overflow="hidden"
        alignItems="center"
        w={12}
        h={6}
        borderRadius="xl"
        bg={checked ? 'secondary' : 'tertiary'}
        transition="colors"
      >
        <x.span
          w={4}
          h={4}
          borderRadius="full"
          bg={checked ? 'white' : 'tertiary-700'}
          transition="transform 0.3s cubic-bezier(0.47, 1.13, 0.39, 1.19)"
          transform={`translateX(${checked ? '28px' : '4px'})`}
        />
      </x.div>
      <x.label
        color="stone"
        text="lead"
        textAlign="left"
        fontFamily="spacegrotesk"
        fontWeight="semibold"
        lineHeight="tight"
        wordBreak="break-word"
        ml={2}
        htmlFor={htmlForId}
      >
        {children}
      </x.label>
    </ContainerStyled>
  )
}

const ContainerStyled = styled(x.div)`
  input:focus ~ div {
    outline: 1px transparent dotted;
    outline-color: tertiary-700;
    outline-offset: 4px;
  }

  :hover div {
    ${({ $checked }) =>
      $checked
        ? css`
            background-color: secondary;
          `
        : css`
            background-color: tertiary-500;
          `}
  }
`
