import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import type { ElementRef } from 'react'

interface SearchContextType {
  buttonRef?: ElementRef<'button'> | null
  prefillQuery?: string
  isOpen?: boolean
  query: string
  setButtonRef: (buttonRef: ElementRef<'button'> | null) => void
  setPrefillQuery: (prefillQuery: string) => void
  setQuery: (prefillQuery: string) => void
  setIsOpen: (isOpen: boolean) => void
}

const defaultSearchContextValue: SearchContextType = {
  buttonRef: null,
  isOpen: undefined,
  prefillQuery: undefined,
  query: '',
  setButtonRef: () => {},
  setIsOpen: () => {},
  setPrefillQuery: () => {},
  setQuery: () => {},
}

const SearchContext = createContext<SearchContextType>(
  defaultSearchContextValue,
)

export function SearchProvider({ children }: PropsWithChildren) {
  const [isOpen, setIsOpen] = useState<boolean | undefined>()
  const [prefillQuery, setPrefillQuery] = useState<string | undefined>()
  const [query, setQuery] = useState<string>('')
  const [buttonRef, setButtonRef] = useState<ElementRef<'button'> | null>(null)

  useEffect(() => {
    if (!isOpen) {
      setQuery('')
    }
  }, [isOpen, setQuery])

  const value = useMemo(
    () => ({
      buttonRef,
      isOpen,
      prefillQuery,
      query,
      setButtonRef,
      setIsOpen,
      setPrefillQuery,
      setQuery,
    }),
    [
      buttonRef,
      prefillQuery,
      isOpen,
      query,
      setButtonRef,
      setQuery,
      setPrefillQuery,
      setIsOpen,
    ],
  )

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  )
}

export function useSearch() {
  return useContext(SearchContext)
}
