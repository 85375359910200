import { createGlobalStyle, css, up } from '@xstyled/styled-components'

const Styled = createGlobalStyle`
  html,
  body {
    -webkit-tap-highlight-color: transparent;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  html {
    ${up(
      'lg',
      css`
        scroll-behavior: auto;
      `,
    )}
  }

  body {
    background-color: uv.neutral.background;
    color: uv.neutral.textStrong;
    font-family: inter;
  }
  
  body.is-embedded .hide-in-embed {
      display: none !important;
  }

  body.is-embedded div#main {
      grid-area: 2 / 1 / 2 / 13;
  }

  [type=search] {
    appearance: none;
  }

  @media (prefers-reduced-motion: reduce) {
    html {
      scroll-behavior: auto;
    }
    *, ::before, ::after {
      animation-delay: -1ms !important;
      animation-duration: 1ms !important;
      animation-iteration-count: 1 !important;
      background-attachment: initial !important;
      scroll-behavior: auto !important;
      transition-duration: 0s !important;
      transition-delay: 0s !important;
    }
  }

  summary:focus,
  input:focus,
  select:focus,
  textarea:focus,
  button:focus,
  a:focus,
  [tabindex="0"]:focus {
    outline-width: thin;
    outline-style: dotted;
    outline-color: currentColor;
    outline-offset: -0.25em;
  } 

  @supports selector(:focus-visible) {
    &:focus,
    &:active {
      outline: none;
    }

    summary:focus-visible,
    input:focus-visible,
    select:focus-visible,
    textarea:focus-visible,
    button:focus-visible,
    a:focus-visible,
    [tabindex="0"]:focus-visible {
      outline-width: thin;
      outline-style: dotted;
      outline-color: currentColor;
      outline-offset: -0.25em;
    }
  }

  label {
    cursor: pointer;
  }

  a {
    color: inherit;
    transition: color .12s ease-out;
  }
  svg {
    transition: fill .12s ease-out;
  }

  .active-link {
    color: secondary;
    font-weight: semibold;
  }
  
  .no-transition {
    * {
      -webkit-transition: none !important;
      -moz-transition: none !important;
      -o-transition: none !important;
      transition: none !important;
    }
  }

  @keyframes jelly-samesize { 
    0% { transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    3.4% { transform: matrix3d(0.658, 0, 0, 0, 0, 0.703, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    4.7% { transform: matrix3d(0.725, 0, 0, 0, 0, 0.8, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    6.81% { transform: matrix3d(0.83, 0, 0, 0, 0, 0.946, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    9.41% { transform: matrix3d(0.942, 0, 0, 0, 0, 1.084, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    10.21% { transform: matrix3d(0.971, 0, 0, 0, 0, 1.113, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    13.61% { transform: matrix3d(1.062, 0, 0, 0, 0, 1.166, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    14.11% { transform: matrix3d(1.07, 0, 0, 0, 0, 1.165, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    17.52% { transform: matrix3d(1.104, 0, 0, 0, 0, 1.12, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    18.72% { transform: matrix3d(1.106, 0, 0, 0, 0, 1.094, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    21.32% { transform: matrix3d(1.098, 0, 0, 0, 0, 1.035, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    24.32% { transform: matrix3d(1.075, 0, 0, 0, 0, 0.98, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    25.23% { transform: matrix3d(1.067, 0, 0, 0, 0, 0.969, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    29.03% { transform: matrix3d(1.031, 0, 0, 0, 0, 0.948, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    29.93% { transform: matrix3d(1.024, 0, 0, 0, 0, 0.949, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    35.54% { transform: matrix3d(0.99, 0, 0, 0, 0, 0.981, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    36.74% { transform: matrix3d(0.986, 0, 0, 0, 0, 0.989, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    41.04% { transform: matrix3d(0.98, 0, 0, 0, 0, 1.011, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    44.44% { transform: matrix3d(0.983, 0, 0, 0, 0, 1.016, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    52.15% { transform: matrix3d(0.996, 0, 0, 0, 0, 1.003, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    59.86% { transform: matrix3d(1.003, 0, 0, 0, 0, 0.995, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    63.26% { transform: matrix3d(1.004, 0, 0, 0, 0, 0.996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    75.28% { transform: matrix3d(1.001, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    85.49% { transform: matrix3d(0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    90.69% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    100% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); } 
  }

  @media print {

    main {
      display: block !important;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 50;
      width: 100vw !important;
      max-width: unset !important;
      height: 100% !important;
      max-height: unset !important;
      background: white;
      margin: 0;
      padding: 5vw 5vw;
      font-family: serif;

      a:not([href^="http"]) {
        color: #000000 !important;
        font-weight: 100;
      }
      a[href^="http"] {
        color: #000000 !important;
      }
      a[href^="http"]:after {
        content: " (" attr(href) ")"; 
        text-decoration: underline !important;
      }
    }

    p, 
    blockquote {
        orphans: 3; 
        widows: 3; 
    }

    blockquote,
    ul,
    ol,
    p {
      page-break-inside: avoid; 
    }

    h1,
    h2, 
    h3, 
    h4, 
    h5, 
    h6, 
    caption {
      page-break-after: avoid; 
    }

    .not-printed {
      display: none !important;
    }
    .printed {
      display: block !important;
    }
    .printed > * {
      margin: auto !important;
    }

  }
`

export default function GlobalStyle() {
  return <Styled />
}
