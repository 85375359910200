/* eslint-disable no-console */
import { PropsWithChildren } from 'react'
import SegmentProvider from '@scaleway/use-segment'
import {
  SegmentConsentMiddleware,
  useCookieConsent,
} from '@scaleway/cookie-consent'
import * as events from './SegmentEvents'

export const ThirdPartyProviders = ({ children }: PropsWithChildren) => {
  const { isSegmentAllowed, segmentIntegrations } = useCookieConsent()
  const writeKey = process.env.GATSBY_SEGMENT_KEY
  const segmentSettings =
    isSegmentAllowed && writeKey
      ? {
          writeKey,
          cdnURL: 'https://evs.nuage01.scaleway.com',
        }
      : undefined

  const isSSR = typeof window === 'undefined'

  const content = isSSR ? (
    children
  ) : (
    <SegmentConsentMiddleware amplitudeIntegrationName="Amplitude (Actions)">
      {children}
    </SegmentConsentMiddleware>
  )

  return (
    <SegmentProvider
      onError={e => console.error('SegmentProvider', e)}
      areOptionsLoaded
      events={events}
      initOptions={{ integrations: segmentIntegrations }}
      settings={segmentSettings}
    >
      {content}
    </SegmentProvider>
  )
}
