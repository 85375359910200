/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable sort-keys */

// Supports variable weights 300-700
import '@fontsource-variable/space-grotesk'
// Supports variable weights 100-900
import '@fontsource-variable/inter'
// Supports weights 100-800
import '@fontsource-variable/jetbrains-mono'

import {
  defaultTheme,
  generateHexAlphaVariants,
} from '@xstyled/styled-components'
import {
  consoleDarkerTheme as swDarkTheme,
  consoleLightTheme as swTheme,
} from '@ultraviolet/themes'

const alphaVariants = [16, 20, 24, 30, 40, 56]

const colors = {
  uv: swTheme.colors,
  // --- Constant colors :
  brand: '#4F0599',
  'brand-300': '#B0AEFF',
  'inline-code': '#F4516D',
  white: '#FFFFFF',
  line: '#FFFFFF',
  'purple-lavender-1': '#ECE8FB',
  'purple-lavender-2': '#E4DCF6',

  ...generateHexAlphaVariants({ secondary: '#BC4FFF' }, alphaVariants),

  // --- Mode colors :
  background: '#FFFFFF',
  stone: '#151A2D',
  ...generateHexAlphaVariants({ primary: '#151A2D' }, alphaVariants),
  ...generateHexAlphaVariants({ tertiary: '#DCE1EB' }, alphaVariants),
  'tertiary-100': '#FAFAFB',
  'tertiary-200': '#F7F8FA',
  'tertiary-300': '#F0F3F7',
  'tertiary-500': '#EDF0F5',
  'tertiary-700': '#98A1B3',
  'tertiary-900': '#98A1B3',
  ...generateHexAlphaVariants({ info: '#80B7FF' }, alphaVariants),
  ...generateHexAlphaVariants({ hit: '#68EA62' }, alphaVariants),
  ...generateHexAlphaVariants({ critical: '#ff8480' }, alphaVariants),
  ...generateHexAlphaVariants({ neutral: '#DCE1EB' }, alphaVariants),

  aquaBlue: '#03D5E0',
  blueDress: '#0092FF',
  jellyfish: '#45D29F',
  portlandOrange: '#FF5D2A',
  radicalRed: '#FF1C6E',
  supernova: '#FFC900',
  eucalyptus: '#119867',

  // --- console colors
  consoleAlert: '#ef5774',
  consoleNeutral: '#5c6275',
  consolePrimary: '#4f0599',
  consoleSecondary: '#babbc2',
  consoleSuccess: '#45d6b5',
  consoleWarning: '#ff8c69',

  modes: {
    _dark: {
      uv: swDarkTheme.colors,
      background: '#151A2D',
      line: '#363D54',
      stone: '#363D54',
      ...generateHexAlphaVariants({ primary: '#FCFCFC' }, alphaVariants),
      ...generateHexAlphaVariants({ tertiary: '#363D54' }, alphaVariants),
      'tertiary-100': '#151A2D',
      'tertiary-200': '#1D2437',
      'tertiary-300': '#262D41',
      'tertiary-500': '#262D41',
      'tertiary-700': '#98A1B3',
      'tertiary-900': '#F0F3F7',
      ...generateHexAlphaVariants({ note: '#4E9BFF' }, alphaVariants),
      ...generateHexAlphaVariants({ tip: '#3DE335' }, alphaVariants),
      ...generateHexAlphaVariants({ important: '#FF524D' }, alphaVariants),
      ...generateHexAlphaVariants({ requirement: '#363D54' }, alphaVariants),
    },
  },
}

const screens = {
  ...defaultTheme.screens,
  '3xl': 1920,
  grid: 1280,
  wide: 1600,
  xwide: 1940,
}

/* eslint sort-keys: ["error", "asc", {natural: false}] */
/* eslint-env es6 */
const space = {
  uv: { space: swTheme.space },
  0: '0',
  0.05: '0.1rem',
  0.5: '0.25rem',
  1: '0.5rem',
  1.5: '0.75rem',
  10: '5rem',
  11: '5.5rem',
  12: '6rem',
  14: '7rem',
  16: '8rem',
  2: '1rem',
  2.5: '1.25rem',
  20: '10rem',
  24: '12rem',
  28: '14rem',
  3: '1.5rem',
  3.5: '1.75rem',
  32: '16rem',
  36: '18rem',
  4: '2rem',
  40: '20rem',
  44: '22rem',
  48: '24rem',
  5: '2.5rem',
  52: '26rem',
  56: '28rem',
  6: '3rem',
  60: '30rem',
  64: '32rem',
  7: '3.5rem',
  72: '36rem',
  8: '4rem',
  80: '44rem',
  9: '4.5rem',
  96: '52rem',
  px: '1px',
}

const sizes = {
  18: '4.5rem',
  ...defaultTheme.sizes,
}

const fonts = {
  ...defaultTheme.fonts,
  inter:
    '"Inter Variable", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
  spacegrotesk:
    '"Space Grotesk Variable", "Helvetica Neue", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
  jetbrains:
    '"JetBrains Mono Variable", ui-monospace, "Cascadia Mono", "Segoe UI Mono", "Ubuntu Mono", "Roboto Mono", Menlo, Monaco, Consolas, monospace',
}

const fontSizes = {
  ...defaultTheme.fontSizes,
  xs: '0.75rem', // 12px = xs
  sm: '0.875rem', // 14px = sm
  default: '1rem', // 16px = default
  lg: '1.125rem', // 18px = lg
  xl: '1.25rem', // 20px = xl
  '2xl': '1.5rem', // 24px = 2xl
  '3xl': '2rem', // 32px = 3xl
  '4xl': '2.5rem', // 40px = 4xl
  '5xl': '3rem', // 48px = 5xl
  '6xl': '3.5rem', // 56px = 6xl
  '7xl': '4rem', // 64px = 7xl
  '8xl': '5.5rem', // 88px = 8xl
  '9xl': '8rem', // 128px = 9xl
}

const lineHeights = {
  ...defaultTheme.lineHeights,
  snug: 1.4,
  slack: 1.8,
}

const transforms = {
  'rotate-0': 'rotate(0)',
  'rotate-180': 'rotate(-180deg)',
  'translate-0': 'translate3d(0, 0, 0)',
  'translate-100': 'translate3d(-100%, 0, 0)',
}

const transitions = {
  ...defaultTheme.transitions,
  'transform-ease-in': 'transform .24s ease-in',
  'transform-ease-out': 'transform .46s ease-out',
}

const shadows = {
  ...defaultTheme.shadows,
  'blur-secondary': `0 0 6px 0 ${colors['secondary-a40']}`,
}

const states = {
  ...defaultTheme.states,
  hoverFocusSvg: '&:hover > svg, &:focus > svg',
  hoverfocus: '&:hover, &:focus',
  targetSvg: '& > svg',
  firstChildAncestor: '& > :first-child',
  hoverFocusBeforeOverride: '&:hover::before, &:focus::before',
  before: '&::before',
}

const radii = {
  uv: { radii: swTheme.radii },
  ...defaultTheme.radii,
}

const theme = {
  ...defaultTheme,
  colors,
  defaultColorModeName: 'default',
  fontSizes,
  fonts,
  initialColorModeName: 'default',
  lineHeights,
  radii,
  screens,
  shadows,
  sizes,
  space,
  states,
  transforms,
  transitions,
}

export default theme
