import { useCallback, useEffect, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import FocusTrap from 'focus-trap-react'
import { useScreens, useViewportWidth, x } from '@xstyled/styled-components'
import { useCookieConsent } from '@scaleway/cookie-consent'
import { useConsents } from '@documentations/commons/contexts/Consents'
import { p as P } from '../Typography'
import { Link } from '../InternalLink'
import Dialog from './Dialog'
import Preference from './Preference'
import Touchable from './Touchable'

export default function CookieBanner() {
  const portalRoot = globalThis?.document?.getElementById('portal') || null

  const { needConsent } = useCookieConsent()

  const { acceptAll, refuseAll } = useConsents()

  const [visible, show] = useState(false)
  const [bodyStyle, setBodyStyle] = useState()
  const breakpoints = useScreens()
  const viewportWidth = useViewportWidth()

  const timeoutId = useMemo(
    () => (needConsent ? setTimeout(() => show(true), 4500) : ''),
    [needConsent],
  )

  const toggleVisible = useCallback(() => {
    if (needConsent && document?.documentElement?.scrollTop > 1) show(true)
  }, [needConsent])

  useEffect(() => {
    if (!needConsent || visible) {
      clearTimeout(timeoutId)
    }
  }, [needConsent, timeoutId, visible])

  useEffect(() => {
    show(false)
    window.removeEventListener('scroll', toggleVisible)
  }, [toggleVisible])

  useEffect(() => {
    setBodyStyle(document.body.style.cssText)
  }, [])

  useEffect(() => {
    if (visible) {
      document.body.style.cssText = `${bodyStyle} overflow-y:hidden;`
    } else {
      document.body.style.cssText = bodyStyle
    }
  }, [visible, bodyStyle, viewportWidth])

  useEffect(() => {
    if (needConsent) {
      window.addEventListener('scroll', toggleVisible)
    } else {
      window.removeEventListener('scroll', toggleVisible)
    }

    return () => {
      window.removeEventListener('scroll', toggleVisible)
    }
  }, [needConsent, toggleVisible])

  return (
    visible &&
    createPortal(
      <FocusTrap>
        <x.div
          position="fixed"
          zIndex={10000}
          top={0}
          left={0}
          bottom={0}
          right={0}
          bg="primary-a56"
        >
          <x.div
            position="fixed"
            zIndex={10001}
            bottom={0}
            w={1}
            borderColor="purple-1"
            borderTopWidth="default"
            boxShadow="blur-purple"
            bg="white"
            px={{ sm: 4, xs: 2 }}
            py={{ _: 2, sm: 4 }}
          >
            <x.div
              position="relative"
              display="grid"
              columnGap={4}
              w={1}
              maxWidth={breakpoints.grid}
              m="auto"
              gridTemplateColumns={{
                _: 'repeat(2, 1fr)',
                sm: 'repeat(8, 1fr)',
                lg: 'repeat(12, 1fr)',
              }}
              gridTemplateRows={{ sm: '1fr' }}
            >
              <x.div
                position="relative"
                zIndex={1}
                gridArea={{
                  _: 'auto / 1 / auto / 3',
                  sm: 'auto / 1 / auto / 9',
                  lg: 'auto / 1 / auto / 8',
                }}
                color="stone"
                mb={{ _: 1, lg: 0 }}
              >
                <x.div
                  fontFamily="spacegrotesk"
                  fontSize="2xl"
                  fontWeight="semibold"
                  py={1}
                >
                  This website uses cookies
                </x.div>
                <P color="stone" py={0}>
                  We use cookies to improve our website. Consult our{' '}
                  <Link
                    to="https://www.scaleway.com/en/cookie/"
                    hrefLang="en"
                    textDecoration={{ _: 'none', hover: 'underline' }}
                    fontWeight="semibold"
                  >
                    Cookie policy
                  </Link>{' '}
                  for more information.
                </P>
                <Dialog noOverflowToggle>
                  <Dialog.Trigger>
                    <x.span
                      display="flex"
                      alignItems="center"
                      color="secondary"
                      textDecoration={{ _: 'none', hover: 'underline' }}
                      fontWeight="semibold"
                      my={2}
                    >
                      Manage your preferences
                    </x.span>
                  </Dialog.Trigger>
                  <Dialog.Body>
                    <Preference maxWidth={688} />
                  </Dialog.Body>
                </Dialog>
              </x.div>
              <x.div
                position="relative"
                zIndex={1}
                gridArea={{
                  _: 'auto / 1 / auto / 3',
                  sm: 'auto / 1 / auto / 9',
                  lg: 'auto / 8 / auto / 13',
                }}
                color="stone"
              >
                <x.div
                  display="flex"
                  alignItems="flex-end"
                  h={1}
                  ml={{ lg: -8, xl: 0 }}
                >
                  <x.div
                    display="flex"
                    flexDirection={{ _: 'column-reverse', sm: 'row' }}
                    alignItems="center"
                  >
                    <Touchable
                      onClick={refuseAll}
                      bg="tertiary-500"
                      color="primary"
                      mt={{ _: 2, sm: 0 }}
                      mr={{ _: 0, sm: 2 }}
                    >
                      No, thanks
                    </Touchable>

                    <Touchable onClick={acceptAll} bg="secondary" color="white">
                      I accept
                    </Touchable>
                  </x.div>
                </x.div>
              </x.div>
            </x.div>
          </x.div>
        </x.div>
      </FocusTrap>,
      portalRoot,
    )
  )
}
