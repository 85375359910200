import type { ReactHTML } from 'react'
import { cloneElement } from 'react'
import { Props, x } from '@xstyled/styled-components'

export function Svg({
  color,
  stroke,
  size,
  width,
  height,
  viewBox,
  asset,
  srOnly = true,
  title,
  ...props
}: Props) {
  const w = width || size || 6
  const h = height || size || 6

  if (!asset || typeof asset !== 'function') return null

  const { type: filetype, props: { children: svgChildren = null } = {} } =
    cloneElement(asset()) || {}

  return filetype && filetype === ('svg' as keyof ReactHTML) ? (
    <x.svg
      aria-hidden={srOnly}
      minWidth={w}
      maxWidth={w}
      minHeight={h}
      maxHeight={h}
      fill={color || 'uv.primary.text'}
      stroke={stroke}
      viewBox={viewBox || `0 0 24 24`}
      focusable={!srOnly}
      preserveAspectRatio="none"
      {...props}
    >
      {title && <title>{title}</title>}
      {svgChildren}
    </x.svg>
  ) : null
}
