import { x } from '@xstyled/styled-components'

export const Typography = props => <x.div color="primary" {...props} />

export const H1 = props => (
  <Typography
    as="h1"
    color="uv.neutral.textStrong"
    fontFamily="spacegrotesk"
    fontSize="3xl"
    fontWeight="semibold"
    lineHeight="snug"
    pt={2}
    {...props}
  />
)
export const h1 = H1

export const H2 = props => (
  <Typography
    as="h2"
    color="uv.neutral.textWeak"
    fontFamily="spacegrotesk"
    fontSize="2xl"
    fontWeight="medium"
    lineHeight="snug"
    pt={2}
    mb={0.5}
    {...props}
  />
)
export const h2 = H2

export const H3 = ({ id, ...props }) => (
  <Typography
    as="h3"
    id={id}
    color="uv.neutral.text"
    fontFamily="spacegrotesk"
    fontSize="xl"
    fontWeight="semibold"
    lineHeight="snug"
    pt={1}
    mb={0.5}
    {...props}
  />
)
export const h3 = H3

export const h4 = props => (
  <Typography
    as="h4"
    fontFamily="spacegrotesk"
    fontSize="lg"
    fontWeight="semibold"
    pt={1}
    mb={0.5}
    {...props}
  />
)
export const h5 = props => (
  <Typography
    as="h5"
    color="tertiary-700"
    fontFamily="spacegrotesk"
    fontSize="lg"
    fontWeight="bold"
    pt={1}
    mb={0.5}
    {...props}
  />
)
export const h6 = props => (
  <Typography
    as="h6"
    color="tertiary-700"
    fontFamily="spacegrotesk"
    fontSize="default"
    fontWeight="bold"
    pt={1}
    mb={0.5}
    {...props}
  />
)

export const p = props => (
  <Typography
    as="p"
    fontSize="default"
    lineHeight="snug"
    pt={2}
    pb={1}
    className="p-li-ol"
    {...props}
  />
)
